import gridIcon from "assets/svg/grid.svg";
import dartboard from "assets/svg/dartboard.svg";
import keyresults from "assets/svg/keyresults.svg";
import tasks from "assets/svg/tasks.svg";
import reviews from "assets/svg/reviews.svg";
import rewards from "assets/svg/rewards.svg";
import orgchart from "assets/svg/orgchart.svg";
import company from "assets/svg/company.svg";
import departments from "assets/svg/departments.svg";
import employess from "assets/svg/employees.svg";
import activeCompany from "assets/svg/activeCompany.svg";
import previlages from "assets/svg/previlages.svg";
import settings from "assets/svg/setups.svg";
import okrManagement from "assets/svg/okr-icon.svg";
import privilegeGroups from "assets/svg/privilege-groups.svg";
import rewardsManagement from "assets/svg/rewardsManagement.svg";
import rewardsManagementactive from "assets/svg/rewardsManagementactive.svg";
import perfomanceManagement from "assets/svg/perfomanceManagement.svg";
import bellcurve from "assets/images/bellcurve.png";
import paper from "assets/images/paper.png";
import questionnaire from "assets/images/questionnaire.png";
import catalog from "assets/images/catalog.png";
import competency from "assets/images/competency.png";
import notificationSettings from "assets/images/notification-settings.png";
import integrations from "assets/svg/integrations.svg";
import { AuthRole } from "utilities";

const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/auth/login",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    //redirect: "/auth/login",
    childrens: [
      //{
      //  component: "Chatbot",
      //  componentPath: "pages/Chatbot",
      //  path: "/chatbot",
      //  name: "Chatbot",
      //  auth: false,
      //  exact: true,
      //},
      {
        component: "Login",
        componentPath: "pages/Auth/Login",
        path: "/login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "Register",
        componentPath: "pages/Auth/Register",
        path: "/register",
        name: "Register",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        componentPath: "pages/Auth/ForgotPassword",
        path: "/forgotpassword",
        name: "ForgotPassword",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPassword",
        componentPath: "pages/Auth/ResetPassword",
        path: "/resetpassword/:token",
        name: "ResetPassword",
        auth: false,
        exact: true,
      }
    ],
  },
  {
    component: "AdminLayout",
    path: "/admin",
    auth: false,
    name: "Admin",
    exact: false,
    redirect: "/admin/setups/company",
    childrens: [
      {
        component: "SliderLarge",
        componentPath: "components/SliderLarge/Testing",
        path: "/slider",
        name: "Slider",
        auth: false,
        exact: false,
      },
      {
        component: "Dashboard",
        componentPath: "pages/Dashboard",
        path: "/dashboard/myteam",
        name: "Dashboard",
        auth: false,
        exact: false,
      },
      {
        component: "Dashboard",
        componentPath: "pages/Dashboard",
        path: "/dashboard/me",
        name: "Dashboard",
        auth: false,
        exact: false,
      },
      {
        component: "Objectives",
        componentPath: "pages/Objectives",
        path: "/objectives",
        name: "Objectives",
        auth: false,
        exact: true,
      },
      {
        component: "KeyResults",
        componentPath: "pages/KeyResults",
        path: "/keyresults",
        name: "KeyResults",
        auth: false,
        exact: true,
      },
      {
        component: "Tasks",
        componentPath: "pages/Tasks",
        path: "/tasks",
        name: "Tasks",
        auth: false,
        exact: true,
      },
      {
        component: "Reviews",
        componentPath: "pages/Reviews/ReviewsTable",
        path: "/reviews",
        name: "Reviews",
        auth: false,
        exact: true,
      },
      {
        component: "ReviewsReport",
        componentPath: "pages/AdvancedReviews/ReviewReport",
        path: "/advancedreviews/:id/:templateId",
        name: "Reviews Report",
        auth: false,
        exact: true,
      },
      {
        component: "Reviews",
        componentPath: "pages/AdvancedReviews",
        path: "/advancedreviews",
        name: "Advanced Reviews",
        auth: false,
        exact: true,
      },
      {
        component: "Reviews",
        componentPath: "pages/Reviews/ReviewsForm",
        path: "/reviews/:id",
        name: "Reviews Form",
        auth: false,
        exact: true,
      },
      {
        component: "TraditionalReviewsReport",
        componentPath: "pages/Reviews/ReviewsReport/ReviewReport",
        path: "/reviews-report/:id/:userId",
        name: "Traditional Reviews Report",
        auth: false,
        exact: true,
      },
      {
        component: "Rewards",
        componentPath: "pages/Rewards",
        path: "/rewards/recognize",
        name: "Rewards",
        auth: false,
        exact: true,
      },
      {
        component: "Rewards",
        componentPath: "pages/Rewards",
        path: "/rewards/rewardsRedemption",
        name: "Rewards",
        auth: false,
        exact: true,
      },
      {
        component: "RewardsNomination",
        componentPath: "pages/Rewards/rewardsNomination",
        path: "/rewards/rewardsNomination",
        name: "RewardsNomination",
        auth: false,
        exact: true,
      },
      {
        component: "IHaveIdea",
        componentPath: "pages/Rewards/ihaveidea",
        path: "/rewards/ihaveidea",
        name: "IHaveIdea",
        auth: false,
        exact: true,
      },
      {
        component: "OrgChart",
        componentPath: "pages/OrgChart",
        path: "/orgChart",
        name: "OrgChart",
        auth: false,
        exact: true,
      },
      {
        component: "Goals",
        componentPath: "pages/Goals",
        path: "/goals",
        name: "Goals",
        auth: false,
        exact: true,
      },
      {
        component: "Sessions",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/Session",
        path: "/sessions",
        name: "Sessions",
        auth: false,
        exact: true,
      },
      {
        component: "Session Calibration",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/SessionCalibration",
        path: "/calibration/:id",
        name: "Session Calibration",
        auth: false,
        exact: true,
      },
      {
        component: "Company",
        componentPath: "pages/Setup/Company",
        path: "/setups/company",
        name: "Company",
        auth: false,
        exact: true,
      },
      {
        component: "Departments",
        componentPath: "pages/Setup/Departments",
        path: "/setups/departments",
        name: "Departments",
        auth: false,
        exact: true,
      },
      {
        component: "Employees",
        componentPath: "pages/Setup/Employees",
        path: "/setups/employees",
        name: "Employees",
        auth: false,
        exact: true,
      },
      {
        component: "RolesAndPreviliges",
        componentPath: "pages/Previleges/RolesAndPrevilages",
        path: "/previlages/rolesandprevilages",
        name: "Roles & Previlages",
        auth: false,
        exact: true,
      },
      {
        component: "OKRManagement",
        componentPath: "pages/Previleges/OKRManagement",
        path: "/previlages/okrmanagement",
        name: "OKRManagement",
        auth: false,
        exact: true,
      },
      {
        component: "QuestionnaireManagement",
        componentPath: "pages/Previleges/QuestionnaireManagement",
        path: "/previlages/questionaireManagement",
        name: "QuestionnaireManagement",
        auth: false,
        exact: true,
      },
      {
        component: "PrivilegeGroups",
        componentPath: "pages/Previleges/PrivilegeGroups",
        path: "/previlages/privilegegroups",
        name: "PrivilegeGroups",
        auth: false,
        exact: true,
      },
      {
        component: "RewardsManagement",
        componentPath: "pages/Previleges/RewardsManagement",
        path: "/previlages/rewardsManagement",
        name: "RewardsManagement",
        auth: false,
        exact: true,
      },
      {
        component: "CatalogManagement",
        componentPath: "pages/Previleges/RewardsManagement/CatalogManagement",
        path: "/previlages/catalogManagement",
        name: "CatalogManagement",
        auth: false,
        exact: true,
      },
      {
        component: "NotificationSettings",
        componentPath: "pages/NotificationSettings/Settings",
        path: "/settings/notificationsettings",
        name: "Notification Settings",
        auth: false,
        exact: true,
      },
      {
        component: "PerformanceManagement",
        componentPath: "pages/Previleges/PerformanceManagement",
        path: "/previlages/performanceManagement",
        name: "PerformanceManagement",
        auth: false,
        exact: true,
      },
      {
        component: "IntegrationManagement",
        componentPath: "pages/Previleges/IntegrationManagement",
        path: "/previlages/integrationManagement",
        name: "IntegrationManagement",
        auth: false,
        exact: true,
      },
      {
        component: "SalesforceIntegrationManagement",
        componentPath: "pages/Previleges/IntegrationManagement/components/auth/Auth",
        path: "/previlages/integrationManagement/salesforce/setup",
        name: "Auth",
        auth: false,
        exact: true,
      },
      {
        component: "SalesforceKpis",
        componentPath: "pages/Previleges/IntegrationManagement/components/salesforce/Kpis",
        path: "/previlages/integrationManagement/salesforce/setup/:id",
        name: "Kpis",
        auth: false,
        exact: true,
      },
      {
        component: "SalesforceKpis",
        componentPath: "pages/Previleges/IntegrationManagement/components/salesforce/Kpiquery",
        path: "/previlages/integrationManagement/salesforce/setup/:id/kpiquery",
        name: "Kpiquery",
        auth: false,
        exact: true,
      },
      {
        component: "CompetencyManagement",
        componentPath: "pages/Previleges/CompetencyManagement",
        path: "/previlages/competencyManagement",
        name: "CompetencyManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ReviewPerformanceManagement",
        componentPath: "pages/Previleges/ReviewPerformanceManagement",
        path: "/previlages/reviewPerformanceManagement",
        name: "ReviewPerformanceManagement",
        auth: false,
        exact: true,
      },
      {
        component: "Templates",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/Templates",
        path: "/previlages/templatesPerformanceManagement",
        name: "Templates",
        auth: false,
        exact: true,
      },
      {
        component: "LaunchForms",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/LaunchForms",
        path: "/previlages/launchFormsPerformanceManagement",
        name: "LaunchForms",
        auth: false,
        exact: true,
      },
      {
        component: "AdvancedLaunchForms",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/AdvancedLaunchForms",
        path: "/previlages/advanced-launchFormsPerformanceManagement",
        name: "AdvancedLaunchForms",
        auth: false,
        exact: true,
      },
      {
        component: "ReviewManagement",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/ReviewManagement",
        path: "/previlages/configureReviewManagement",
        name: "ReviewManagement",
        auth: false,
        exact: true,
      },
      {
        component: "Session",
        componentPath: "pages/Previleges/ReviewPerformanceManagement/Session",
        path: "/previlages/calibrationManagement",
        name: "Session",
        auth: false,
        exact: true,
      },
      {
        component: "FormCreate",
        componentPath: "pages/Setup/Employees/FormCreate",
        path: "/setups/employeeform",
        name: "FormCreate",
        auth: false,
        exact: true,
      },
      {
        component: "FormEdit",
        componentPath: "pages/Setup/Employees/FormEdit",
        path: "/setups/employeeEdit",
        name: "FormEdit",
        auth: false,
        exact: true,
      },
      {
        component: "Previlages",
        componentPath: "pages/Previlages",
        path: "/Previlages",
        name: "Previlages",
        auth: false,
        exact: true,
      },
      {
        component: "OkrDetailsID",
        componentPath: "pages/Objectives/OkrDetailsID",
        path: "/objectives/okr-details/:id",
        name: "OKR-Details",
        auth: false,
        exact: true,
      },
      {
        component: "OKRDetails",
        componentPath: "pages/Objectives/OkrDetails",
        path: "/objectives/okrdetails",
        name: "OKRDetails",
        auth: false,
        exact: true,
      },
      {
        component: "Notifications",
        componentPath: "pages/Notifications",
        path: "/notifications",
        name: "Notifications",
        auth: false,
        exact: true,
      },
      {
        component: "RewardPointsManagement",
        componentPath: "pages/Previleges/RewardPointsManagement",
        path: "/reward-points-management",
        name: "Reward Points Management",
        auth: false,
        exact: true,
      },

    ],
  },
];


// sidebar links
export const links = () => {
  let result = [];
  let privileges = localStorage.getItem("privileges") !== null ? JSON.parse(localStorage.getItem("privileges")) : null;
  const AuthRole = localStorage.getItem("AuthRole"); // Assuming you get AuthRole from localStorage

  if (privileges !== null) {
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Dashboards" && privilege.view).length > 0) {
      result.push({
        icon: gridIcon,
        title: 'Sidebar.Dashboard',
        link: "/admin/dashboard/myteam",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Objectives" && privilege.view).length > 0) {
      result.push({
        icon: dartboard,
        title: 'Sidebar.Objectives',
        link: "/admin/objectives",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Key Results" && privilege.view).length > 0) {
      result.push({
        icon: keyresults,
        title: 'Sidebar.KeyResults',
        link: "/admin/keyresults",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Tasks" && privilege.view).length > 0) {
      result.push({
        icon: tasks,
        title: 'Sidebar.Tasks',
        link: "/admin/tasks",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Reviews" && privilege.view).length > 0) {
      result.push({
        icon: reviews,
        title: 'Sidebar.Reviews',
        link: "/admin/reviews",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Advanced Reviews" && privilege.view).length > 0) {
      result.push({
        icon: reviews,
        title: 'Sidebar.AdvancedReviews',
        link: "/admin/advancedreviews",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Rewards" && privilege.view).length > 0) {
      result.push({
        icon: rewards,
        title: 'Sidebar.RewardsRedemption',
        link: "/admin/rewards/rewardsRedemption",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "RewardsNomination" && privilege.view).length > 0) {
      result.push({
        icon: rewards,
        title: 'Sidebar.RewardsNomination',
        link: "/admin/rewards/rewardsNomination",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "IHaveIdea" && privilege.view).length > 0) {
      result.push({
        icon: rewards,
        title: 'Sidebar.IHaveIdea',
        link: "/admin/rewards/ihaveidea",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Org Chart" && privilege.view).length > 0) {
      result.push({
        icon: orgchart,
        title: 'Sidebar.OrgChart',
        link: "/admin/orgchart",
      });
    }
    if ((AuthRole === "Super Admin") || privileges.filter(privilege => privilege.page === "Goals" && privilege.view).length > 0) {
      result.push({
        icon: dartboard,
        title: 'Sidebar.Goals',
        link: "/admin/goals",
      });
    }
    if ((AuthRole === "Super Admin" || AuthRole === "HR Admin" || AuthRole === "Manager") && privileges.filter(privilege => privilege.page === "Sessions" && privilege.view).length > 0) {
      result.push({
        icon: bellcurve,
        title: 'Sidebar.Sessions',
        link: "/admin/sessions",
      });
    }
    if ((AuthRole === "Super Admin" || AuthRole === "HR Admin" || AuthRole === "Manager") && privileges.filter(privilege => privilege.page === "Advanced Launch Forms" && privilege.view).length > 0) {
      result.push({
        icon: paper,
        title: 'Sidebar.AdvancedLaunchForms',
        link: "/admin/previlages/advanced-launchFormsPerformanceManagement",
      });
    }
    if ((AuthRole === "Super Admin" || AuthRole === "HR Admin" || AuthRole === "Manager") && privileges.filter(privilege => privilege.page === "Org Chart" && privilege.view).length > 0) {
      result.push({
        icon: rewards,
        title: 'Sidebar.RewardPoints',
        link: "/admin/reward-points-management",
      });
    }
  }
  return result;
};

//sidebar admin activity links
let adminActivityLinksUpdated = [
  {
    icon: company,
    activeIcon: activeCompany,
    title: 'Sidebar.Company',
    link: "/admin/setups/company"
  },
  {
    icon: departments,
    activeIcon: activeCompany,
    title: 'Sidebar.Departments',
    link: "/admin/setups/departments"
  },
  {
    icon: employess,
    activeIcon: activeCompany,
    title: 'Sidebar.Employees',
    link: "/admin/setups/employees"
  }
];

export const adminActivityLinks = (AuthRole === "Super Admin") ? adminActivityLinksUpdated : adminActivityLinksUpdated.slice(1, 3)





export const rolesAndPrevilages = [
  {
    icon: previlages,
    activeIcon: activeCompany,
    title: 'Sidebar.RolesAndPrivileges',
    link: "/admin/previlages/rolesandprevilages"
  },
  {
    icon: privilegeGroups,
    activeIcon: activeCompany,
    title: 'Sidebar.PrivilegeGroups',
    link: "/admin/previlages/privilegegroups"
  },
  {
    icon: okrManagement,
    activeIcon: activeCompany,
    title: 'Sidebar.OKRManagement',
    link: "/admin/previlages/okrmanagement"
  },
  {
    icon: rewardsManagement,
    activeIcon: rewardsManagementactive,
    title: 'Sidebar.RewardsManagement',
    link: "/admin/previlages/rewardsManagement"
  },
  {
    icon: catalog,
    activeIcon: rewardsManagementactive,
    title: 'Sidebar.CatalogManagement',
    link: "/admin/previlages/catalogManagement"
  },
  {
    icon: notificationSettings,
    activeIcon: activeCompany,
    title: 'Sidebar.NotificationSettings',
    link: "/admin/settings/notificationsettings"
  },
  {
    icon: perfomanceManagement,
    activeIcon: activeCompany,
    title: 'Sidebar.AdvancedPerformanceManagement',
    link: "/admin/previlages/performanceManagement"
  },
  {
    icon: integrations,
    activeIcon: activeCompany,
    title: 'Sidebar.IntegrationManagement',
    link: "/admin/previlages/integrationManagement"
  },
  {
    icon: questionnaire,
    activeIcon: activeCompany,
    title: 'Sidebar.QuestionnaireManagement',
    link: "/admin/previlages/questionnaireManagement"
  },
  {
    icon: competency,
    activeIcon: activeCompany,
    title: 'Sidebar.CompetencyManagement',
    link: "/admin/previlages/competencyManagement"
  },
  {
    icon: perfomanceManagement,
    activeIcon: activeCompany,
    title: 'Sidebar.PerformanceManagementTemplates',
    link: "/admin/previlages/templatesPerformanceManagement"
  },
  {
    icon: paper,
    activeIcon: activeCompany,
    title: 'Sidebar.LaunchFormsManagement',
    link: "/admin/previlages/launchFormsPerformanceManagement"
  },
  {
    icon: bellcurve,
    activeIcon: activeCompany,
    title: 'Sidebar.CalibrationManagement',
    link: "/admin/previlages/calibrationManagement"
  },
  {
    icon: paper,
    activeIcon: activeCompany,
    title: 'Sidebar.AdvancedLaunchFormsManagement',
    link: "/admin/previlages/advanced-launchFormsPerformanceManagement"
  }
];


export const createOKR = [
  {
    icon: "company",
    activeIcon: activeCompany,
    title: "Library OKR",
    link: "/admin/setups/company"
  },
  {
    icon: departments,
    activeIcon: activeCompany,
    title: "Non Library OKRY",
    link: "/admin/setups/departments"
  }
]

export default routers